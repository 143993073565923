import React from "react"
import { parseISO } from "date-fns"
import { format } from "date-fns-tz"

/* Import Global Component(s) */
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Asset(s) */
import CrossSvgSrc from "~assets/images/close.svg"
import DownloadSvgSrc from "./assets/images/download.svg"

/* Import Local Style(s) */
import "./post-header.scss"

const PostHeader = ({
  location,
  post: { publicationDate, title, time, file },
}) => {
  const prevUrlPath = location.state?.prevUrlPath

  return (
    <header className="post-header">
      {file && file.asset && (
        <a
          href={file.asset.url}
          className="download"
          target="_blank"
          rel="noreferrer"
        >
          <img src={DownloadSvgSrc} alt="Download" />
        </a>
      )}
      <h1>
        <span className="date">{`${format(
          parseISO(publicationDate),
          "d MMM yyyy"
        )}`}</span>
        <span>{title}</span>
      </h1>
      <Link className="close" to={prevUrlPath ? prevUrlPath : "/news"}>
        <img src={CrossSvgSrc} alt="Cross" />
      </Link>
    </header>
  )
}

export default PostHeader
